import {useContext, useEffect, useState} from 'react';

import {apiUrl} from '@/backend';
import {toQueryString} from '@/utils/queryString';
import {AuthorizationContext} from "@/contexts/authorization";

const IMAGE_CACHE: Map<string, string> = new Map()

export function useImageLoader(identityUuid: string, size?: string): string | null {
    const [imageData, setImageData] = useState<string | null>(null)
    const {getAccessToken} = useContext(AuthorizationContext);
    const NOT_FOUND: string = "404_NOT_FOUND"

    useEffect(() => {
        if (!identityUuid) {
            return;
        }

        const imageUrl = `${apiUrl}/image/${identityUuid}?${toQueryString({size})}`;
        const imageFromCache = IMAGE_CACHE.get(imageUrl);

        if (imageFromCache){
            if (imageFromCache === NOT_FOUND) {
                return;
            }

            setImageData(imageFromCache);
            return;
        }

        const abortController = new AbortController();

        getAccessToken()
        .then((token: string) => fetch(imageUrl, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: `Bearer ${token}`,
            },
            cache: 'default',
            credentials: 'same-origin',
            signal: abortController.signal,
        }))
        .then((response: Response) => {
            if (response.status === 200) {
                return response.blob();
            } else {
                // If the image is not found (404), we cache the value to prevent further requests:
                IMAGE_CACHE.set(imageUrl, NOT_FOUND)
                return null;
            }
        })
        .then((blob: Blob) => {
            if (blob) {
                const image = URL.createObjectURL(blob);
                setImageData(image);
                IMAGE_CACHE.set(imageUrl, image)
            }
        })
        .catch((e: any) => {
        });

        return () => abortController.abort();
    }, [setImageData, identityUuid, size, getAccessToken]);

    return imageData;
}

import {Theme} from '@liquid-design/liquid-design-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {initI18n} from './i18n';
import {Logger} from './logger';
import translations from './translations';
import {Configuration, PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {App} from "./App";
import {restGet} from "@/backend";
import {Constants} from "@azure/msal-common";

const log = new Logger({module: 'index'});

initI18n(translations);

restGet("/api/config")
  .then(res => res.json())
  .then(configuration => {
    const msalConfiguration: Configuration = {
      auth: {
        clientId: configuration.clientId,
        authority: configuration.authority,
        redirectUri: window.location.protocol + "//" + window.location.host,
        navigateToLoginRequestUrl: true,
        protocolMode: "OIDC",
        knownAuthorities: [configuration.knownHost],
        OIDCOptions: {
          defaultScopes: [Constants.OPENID_SCOPE, Constants.PROFILE_SCOPE],
        }
      }
    };

    const msalInstance = new PublicClientApplication(msalConfiguration);

    ReactDOM.render(
      <Theme themeName="richPurple">
        <MsalProvider instance={msalInstance}>
          <App/>
        </MsalProvider>
      </Theme>,
      document.getElementById('app'),
    );
  });

function onUnhandledPromiseRejection(event: Event) {
  log.error('Unhandled promise rejection.', {event});
}

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.dispose(() => {
      window.removeEventListener('unhandledrejection', onUnhandledPromiseRejection);
    });
  }

  window.addEventListener('unhandledrejection', onUnhandledPromiseRejection);
}

import React, {createContext, useCallback, useState} from "react";
import {InteractionType} from "@azure/msal-browser";
import {useMsal, useMsalAuthentication} from "@azure/msal-react";
import {OIDC_DEFAULT_SCOPES} from "@azure/msal-common";

export interface Authorization {
    getAccessToken: () => Promise<string>
}

OIDC_DEFAULT_SCOPES.splice(OIDC_DEFAULT_SCOPES.indexOf('offline_access'), 1);

export const AuthorizationContext = createContext<Authorization>(null);

export function AuthorizationContextProvider({children}: { children: React.ReactNode }) {
    const [accessToken, setAccessToken] = useState(null);
    
    useMsalAuthentication(InteractionType.Redirect, {
        scopes: []
    });

    const {instance, accounts} = useMsal();

    const getAccessToken: () => Promise<string> = useCallback(() => {
        if (accessToken && isTokenValid(accessToken)) {
            return Promise.resolve(accessToken);
        }

        const accessTokenRequest = {
            scopes: [] as Array<string>,
            account: accounts[0]
        };

        return instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            setAccessToken(accessTokenResponse.accessToken);
            return accessTokenResponse.accessToken;
        });

    }, [instance, accounts, accessToken]);

    return <AuthorizationContext.Provider value={{getAccessToken}}>
        {children}
    </AuthorizationContext.Provider>
}

function decodeJwt(token: string) {
    // Split the token into its three parts: header, payload, and signature
    const base64Url = token.split('.')[1];

    // Replace the Base64-URL characters with Base64 standard characters
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Decode the Base64 string to get the JSON payload
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    // Parse and return the JSON object containing the claims
    return JSON.parse(jsonPayload);
}

function isTokenValid(token: string, bufferInSeconds = 10) {
    if (!token) {
        return false;
    }

    const decodedToken = decodeJwt(token);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    // Check, if token is valid:
    return decodedToken.exp && (decodedToken.exp > (currentTimeInSeconds + bufferInSeconds));
}

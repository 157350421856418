import gql from 'graphql-tag';

import {mapResult} from '@/utils/queryUtils';

import {useConfig} from './config';
import {useQuery} from "react-apollo-hooks";

function useConfiguredData(config: { _query: string; [key: string]: any }, variables: any) {
  const query = useQuery(gql(config._query), {variables, fetchPolicy: "no-cache"});

  return {
    data: mapResult(query.data, config),
    errors: query.errors,
    query,
  };
}

export function useTeamMembers(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.team, {uuid});
}

export function useDelegatedTeamMembers(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.delegatedTeam, {uuid});
}

export function useContractData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.data, {uuid});
}

export function usePersonalInformationData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.personalInformation, {uuid});
}

export function useOverviewData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.overview, {uuid});
}

export function useEquipmentData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.equipment, {uuid});
}

export function useAccountData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.accounts, {uuid});
}

export function useAccountabilitiesData(uuid: string) {
  const config = useConfig();
  return useConfiguredData(config.identity.accountabilities, {uuid});
}
